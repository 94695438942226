@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  font-size: 16px;
  @apply bg-background leading-6 min-w-[250px];
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  border: none;
  font-family: "new-atten-normal";
  font-size: 14px;
  user-select: none;
  @apply transition-colors;
}

.no-scroll {
  overflow: hidden;
}

/* intall "new atten" fonts */
@font-face {
  font-family: "new-atten-normal";
  src: url("./assets/fonts/atten/NEW\ ATTEN\ REGULAR.OTF");
}
@font-face {
  font-family: "new-atten-thin";
  src: url("./assets/fonts/atten/NEW\ ATTEN\ BOOK.OTF");
}
@font-face {
  font-family: "new-atten-medium";
  src: url("./assets/fonts/atten/NEW\ ATTEN\ MEDIUM.OTF");
}
@font-face {
  font-family: "new-atten-bold";
  src: url("./assets/fonts/atten/NEW\ ATTEN\ BOLD.OTF");
}
@font-face {
  font-family: "new-atten-extra-bold";
  src: url("./assets/fonts/atten/NEW\ ATTEN\ EXTRABOLD.OTF");
}

/* install "lora" fonts */
@font-face {
  font-family: "lora-normal";
  src: url("./assets/fonts/lora/Lora-Regular.ttf");
}
@font-face {
  font-family: "lora-thin";
  src: url("./assets/fonts/lora/Lora-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "lora-thin-italic";
  src: url("./assets/fonts/lora/Lora-Italic.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "lora-medium";
  src: url("./assets/fonts/lora/Lora-Medium.ttf");
}
@font-face {
  font-family: "lora-semi-bold";
  src: url("./assets/fonts/lora/Lora-SemiBold.ttf");
}
@font-face {
  font-family: "lora-bold";
  src: url("./assets/fonts/lora/Lora-Bold.ttf");
}
